import React, { useState } from "react"
import { IconButton, Box, Snackbar, Alert } from "@mui/material"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import { tokens } from "../../theme"
import Header from "../../components/Header"
import { useTheme } from "@mui/material"
import axios from "axios"
import { useQuery } from 'react-query'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import LinearProgress from '@mui/material/LinearProgress';
import { PATHS } from "../../utils/PathUtils"



const AllApps = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [showAlert, setShowAlert] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const fetchAvailableApps = useQuery('fetchApps', () =>
        axios(`${PATHS.FED_ML}/fedml/get_available_apps_to_upload`))
    
    const startApp = async (appName: string) => {
        console.log('App name', appName)
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${PATHS.FED_ML}/fedml/task/submit-job/${appName}`
        }
        const response = await axios.request(config)
        if (response.data.status ==='SUCCESS') {
            setResponseMessage(response.data.details.message)
            if (response.data.details.message.length > 0) {
                setShowAlert(true)
            }
            console.log(response)
            fetchAvailableApps.refetch()
            
        }
    }

    

    const columns = [
        { field: "name", headerName: "Name", flex: 1 },
        {
            field: "restart", headerName: "", hide: true, flex: .25,
            renderCell: (params: {row: {name: string}}) => {
                return (<>
                    <IconButton onClick={
                        async () => {
                            await startApp(params.row.name)
                            setShowAlert(true)
                        }
                    }>
                        <RocketLaunchIcon />
                    </IconButton>
                </>)
            }
        }
    ]

    return (
        <Box m="0 100px 0 100px">
            <Header
                title="List of Available Apps"
                subtitle=""
            />
            <Snackbar
                open={showAlert}
            >
                < Alert variant="filled" severity="success"
                    onClose={() => {
                        setResponseMessage('')
                        setShowAlert(false)
                    }}
                > {responseMessage}</Alert>
            </Snackbar>
            <Box
                m="0 0 50px 0"
                height="73vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    }
                }}
            >
                <>
                {fetchAvailableApps.error && (
                    <div>
                        <h1>Could not retrieve data</h1>
                    </div>
                )}

                {!fetchAvailableApps.error &&
                    <DataGrid
                        loading={fetchAvailableApps.isLoading}
                        slots={{
                            loadingOverlay: LinearProgress,
                        }}
                        rows={
                            fetchAvailableApps.data?.data.details.app_list.map((name: string, index: number) => {
                                return {
                                    id: index,
                                    name
                                }
                            })|| []
                            
                        }
                        columns={columns}
                        components={{ Toolbar: GridToolbar }}
                    />
                }
                </>
            </Box>
        </Box>
    )
}

export default AllApps
