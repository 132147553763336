import {
  Alert,
  Typography,
  Box,
  Snackbar,
  useTheme,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { PATHS } from "../../utils/PathUtils";

interface Job {
  id: number;
  "JOB ID": string;
  NAME: string;
  "RUN DURATION": string;
  STATUS: string;
  "SUBMIT TIME": string;
  CLIENT: string;
}

const DownloadModel = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [statusMessage, setStatusMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {        
    setIsLoading(true);
    try {
      axios(`${PATHS.FED_ML}/fedml/list/jobs`)
        .then((result) => {
          let rjobs = result.data.reverse();
          rjobs = rjobs.map((x: Job, index:number) => {
            return {
              ...x,
              "SUBMIT TIME": new Date(x["SUBMIT TIME"]).toLocaleString(),
              id:index
            };
          });

          setJobs(rjobs);
        })
        .catch((reason) => {
          setError(reason);
          console.error("Failed to get jobs", reason);
        });
    } finally {
      setIsLoading(false);
    }
  }, []);


  const columns = [
    { field: "JOB ID", headerName: "Job ID", flex: 1 },
    {
      field: "",
      headerName: "Download",
      flex: 0.5,
      renderCell: (params: { row: Job }) => {
        return (
             <Typography
            variant="h6"
            fontWeight="bold"
          >
              <a href={`${PATHS.FED_ML}/fedml/task/download_job/${params.row["JOB ID"]}`} download style={{ color: colors.primary[100] }}>Download</a>
          </Typography>
          
        );
      },
    },
  ];

  return (
    <Box m="0 100px 0 100px">
      <Header title="All Jobs" subtitle="" />
      <Snackbar open={statusMessage.length > 0} message="">
        <Alert
          variant="filled"
          severity="error"
          onClose={() => {
            setStatusMessage("");
          }}
        >
          {" "}
          {statusMessage}
        </Alert>
      </Snackbar>
      <Box
        m="0 0 50px 0"
        height="73vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <>
          {error && (
            <div>
              <h1>Could not retrieve data</h1>
            </div>
          )}

          {!isLoading && !error && (
            <DataGrid
              rows={jobs}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
            />
          )}
        </>
      </Box>
    </Box>
  );
};

export default DownloadModel;
