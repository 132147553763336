import React from "react";

export interface UserData {
  sub: string;
  exp: number;
}

function parseJwt(token: String) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  const userData: UserData = JSON.parse(jsonPayload);

  return userData;
}

export function hasExpired(userData: UserData) {
  if (!userData?.exp || userData.exp * 1000 < Date.now()) {
    return false;
  }
  return true;
}

export default function useUser() {
  const [user, setUser] = React.useState<any>(undefined);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadUser() {
      const userToken = window.localStorage.getItem("userData");
      let userData: { sub: string; exp: number } | undefined = undefined;
      if (userToken) {
        userData = JSON.parse(userToken);
        // userData = parseJwt(userToken);
      }

      setUser(userData);
    }

    loadUser();
  }, []);

  return [user, setUser];
}
