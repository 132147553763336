import CloudIcon from '@mui/icons-material/Cloud';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ComputerIcon from '@mui/icons-material/Computer';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import StartIcon from '@mui/icons-material/Start';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import { PATHS } from "../../utils/PathUtils";

interface ItemProps {
    title: string
    to: string
    icon: any
    selected: string
    setSelected: (title: string) => void
}

const Item = ({ title, to, icon, selected, setSelected }: ItemProps) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: colors.grey[100],
            }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>
    );
};

const Sidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState("Dashboard");

    return (
        <Box
            sx={{
                "& .pro-sidebar-inner": {
                    background: `${colors.primary[400]} !important`,
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important",
                },
                "& .pro-inner-item": {
                    padding: "5px 35px 5px 20px !important",
                },
                "& .pro-inner-item:hover": {
                    color: "#868dfb !important",
                },
                "& .pro-menu-item.active": {
                    color: "#6870fa !important",
                },
            }}
        >
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    {/* LOGO AND MENU ICON */}
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: "10px 0 20px 0",
                            color: colors.grey[100],
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <Typography variant="h5" color={colors.grey[100]}>
                                    iFusion
                                </Typography>
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

              

                    <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                        <Item
                            title="Dashboard"
                            to={PATHS.HOME}
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        {/* <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: "15px 0 5px 20px" }}
                        >
                            Data
                        </Typography> */}
                        <Item
                            title="Nvidia Flare UI"
                            to={`${PATHS.FED_ML}/`}
                            icon={<CloudIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Client/Server Status"
                            to={PATHS.CLIENT_SERVER_STATUS}
                            icon={<ComputerIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Show all jobs"
                            to={PATHS.ALL_JOBS}
                            icon={<FormatListBulletedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        {/* <Item
                            title="Submit a job"
                            to="/"
                            icon={<SaveIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        /> */}
                        <Item
                            title="Upload an App"
                            to={PATHS.UPLOAD_APP}
                            icon={<CloudUploadIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Train app"
                            to={PATHS.ALL_APPS}
                            icon={<StartIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Download Model"
                            to={PATHS.DOWNLOAD_MODEL}
                            icon={<CloudDownloadIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Logout"
                            to={PATHS.LOGOUT}
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    );
};

export default Sidebar;