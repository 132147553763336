import React, { useState, useEffect } from "react"
import { useTheme } from "@mui/material"
import { ResponsiveBar } from "@nivo/bar"
import { tokens } from "../theme"
import axios from "axios"
import { useQuery } from 'react-query'
import { PATHS } from "../utils/PathUtils"

const BarChart = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  interface BarProps {
    client: string
    jobs: number
  }
  const [mockBarData, setMockBarData] = useState<any[]>([])
  const fetchJobs = useQuery('fetchJobs', () =>
    axios(`${PATHS.FED_ML}/fedml/list/jobs`))

  useEffect(() => {
    if (fetchJobs.data) {
      const jobs = fetchJobs.data.data
      const jobsByClient = jobs.reduce((acc: any, job: any) => {
        if (acc[job.CLIENT]) {
          acc[job.CLIENT] += 1
        } else {
          acc[job.CLIENT] = 1
        }
        return acc
      }, {})
      const barData = Object.keys(jobsByClient).map((client) => {
        return {
          client,
          "Submitted Jobs": jobsByClient[client]
        }
      })
      setMockBarData(barData)
    }
  }, [fetchJobs.data])

  return (
    <ResponsiveBar
      data={mockBarData}
      theme={{
        // added
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100]
            },
          },
          legend: {
            text: {
              fill: colors.grey[100]
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
      }}
      keys={["Submitted Jobs"]}
      indexBy="client"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "nivo" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]]
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "client", // changed
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "number of jobs", // changed
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " in clients: " + e.indexValue;
      }}
      tooltip={({ id, value, color }) => (
        < div
          style={{ padding: 12, color: colors.grey[900], background: colors.grey[100] }}>
                  <strong>{ id }: { value }</strong >
        </div >
      )}
    />
  );
};

export default BarChart;
