export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const subpath = "/";

export const BASE_PATH = isLocalhost ? "/" : subpath;

export const HOME_PATH = isLocalhost ? "/" : `${subpath}index.html`;

export const PATHS = {
  HOME: isLocalhost ? "/" : `${BASE_PATH}index.html`,
  ALL_JOBS: `${BASE_PATH}allJobs`,
  ALL_APPS: `${BASE_PATH}allApps`,
  CLIENT_SERVER_STATUS: `${BASE_PATH}ClientServerStatus`,
  PIE: `${BASE_PATH}pie`,
  LINE: `${BASE_PATH}line`,
  FAQ: `${BASE_PATH}faq`,
  UPLOAD_APP: `${BASE_PATH}uploadApp`,
  GEOGRAPHY: `${BASE_PATH}geography`,
  SHOW_RESULTS: `${BASE_PATH}showResults`,
  DOWNLOAD_MODEL: `${BASE_PATH}downloadModel`,
  FED_ML: "https://fedml-ui.inoviaai.se",
  LOGOUT: `${BASE_PATH}logout`,
};

